import {
  decodeCheckoutData,
  decodeStatusData,
  extractIntegrationData,
  parseAppSectionParams,
  extractRestrictedPageParams,
  EmptyCheckoutDataError,
  LowercaseCheckoutDataError,
} from '@wix/pricing-plans-router-utils';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { SubPage } from '../../../types/common';
import { withMonitoring } from '../../../utils/decorators';
import { isDayful } from '../../../utils/is-dayful';
import { getSectionId, getSubPagePath } from '../../../utils/navigation';

export class Navigation {
  constructor(
    protected flowAPI: ControllerParams['flowAPI'],
    protected appDefinitionId: string,
    public readonly isMultiPageApp: boolean,
  ) {}

  get wixCodeApi() {
    return this.flowAPI.controllerConfig.wixCodeApi;
  }

  async setCurrentPath(subPage: SubPage) {
    const { appDefinitionId, isMultiPageApp } = this;
    const sectionId = getSectionId(subPage.name, { isMultiPageApp });
    const path = getSubPagePath(subPage, { isMultiPageApp });
    const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl({ appDefinitionId, sectionId });
    const currentUrl = '/' + this.wixCodeApi.location.path.join('/');
    const disableScrollToTop = currentUrl.startsWith(relativeUrl!);
    this.wixCodeApi.location.to!((relativeUrl ?? '') + path, { disableScrollToTop });
  }

  getCurrentSubPage = withMonitoring(
    this.flowAPI,
    [EmptyCheckoutDataError, LowercaseCheckoutDataError],
    async (): Promise<SubPage> => {
      const { location } = this.wixCodeApi;
      const { path } = location;
      if (path[1] === 'payment') {
        return { name: 'checkout', checkoutData: decodeCheckoutData(path[2]) };
      } else if (path[1] === 'status') {
        return {
          name: 'status',
          statusData: decodeStatusData(path[2]),
        };
      } else {
        const params = parseAppSectionParams(location.query?.appSectionParams);
        const restrictedPageParams = extractRestrictedPageParams(params);
        const { integrationData, decodingErrors } = extractIntegrationData(params);

        if (decodingErrors) {
          this.flowAPI.errorMonitor.captureException(new Error('Unable to decode integration data'), {
            contexts: {
              site: { url: this.flowAPI.controllerConfig.wixCodeApi.location.url },
              integrationData: params,
              decodingErrors,
            },
          });
        }

        if (restrictedPageParams) {
          return { name: 'restricted', restrictedPageParams, integrationData };
        }
        return {
          name: 'list',
          integrationData: (await isDayful(this.flowAPI, this.wixCodeApi))
            ? { ...integrationData, navigateTo: location.baseUrl + '/_api/dayful/sch/route' }
            : integrationData,
        };
      }
    },
  );
}
